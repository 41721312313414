<script setup>
    const props = defineProps({
    params: Object
});
</script>

<template>
    <v-dialog width="600">
        <template v-slot:activator="{ props }">
            <v-btn size="x-small" variant="text" v-bind="props" text="View Payload" />
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Request Payload">
                <v-card-text>
                    <vue-json-pretty :data="props.params.data.payload" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text="Close" @click="isActive.value = false"></v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
