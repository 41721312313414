<script setup>
import { ref, onMounted } from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import { useThemeStore } from "@/stores/themeStore";
import pageTitle, { setPageTitle } from "../../../utils/pageTitle";
import {
  connectToServerApi
} from "@/utils/agGridUtils";
import JSONRenderedCellRenderer from '../../../components/JSONCellRenderer.vue';

const themeStore = useThemeStore();
let gridApi;

const WOOCOMMERCE_LOGS_URL = "/woocommerce/logs";

const advancedFilterKeys = ["id"];

const context = ref(null);

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl:WOOCOMMERCE_LOGS_URL,
    advancedFilterKeys:advancedFilterKeys
  };
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

onMounted(() => {
  setPageTitle("Woocommerce Logs");
});

const defaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  suppressMenu: true,
  resizable: true,
  width: 120,
  wrapText: true,   // Enable text wrapping
  autoHeight: true,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const gridOptions = {
  rowModelType: "serverSide",
  rowSelection: "multiple",
  autoSizeStrategy: {
    type: "fitCellContents",
  },
  enableRangeSelection: true
};

const colDefs = ref([
  {
    field: "id",
    colId: "id",
    headerName: "#",
    cellDataType: "number",
  },
  {
    field: "workflow_state",
    colId: "workflow_state",
    headerName: "Workflow State",
    cellDataType: "text",
  },
  {
    field: "log_type",
    colId: "log_type",
    headerName: "Log Type",
    cellDataType: "text",
  },
  {
    field: "payload",
    colId: "payload",
    headerName: "Payload",
    cellDataType: "text",
    cellRenderer: JSONRenderedCellRenderer
  },
  {
    field: "operation_errors",
    colId: "operation_errors",
    headerName: "Operation Errors",
    cellDataType: "text",
  },
  {
    field: "order_type",
    colId: "order_type",
    headerName: "Order Type",
    cellDataType: "text",
  },
  {
    field: "created_by.name",
    colId: "created_by.name",
    headerName: "Created By",
    cellDataType: "text",
  },
  {
    field: "tenant.name",
    colId: "tenant.name",
    headerName: "Tenant",
    cellDataType: "text",
  },
  {
    field: "created_at",
    colId: "created_at",
    headerName: "Created At",
    cellDataType: "dateString",
  },
  {
    field: "updated_at",
    colId: "updated_at",
    headerName: "Updated At",
    cellDataType: "dateString",
  },
]);

</script>

<template>

  <div class="h-100 ml-4 mr-4">
    <v-card-title class="mb-4">{{ pageTitle }}</v-card-title>
    <AgGridVue class="h-100" :class="themeStore.agTheme" :columnDefs="colDefs" :gridOptions="gridOptions"
      :defaultColDef="defaultColDef" @grid-ready="onGridReady" :context="context">
    </AgGridVue>
  </div>

</template>